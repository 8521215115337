(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:PhasesInscriptions
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('PhasesInscriptions', PhasesInscriptions);

  function PhasesInscriptions($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phasesInscriptions');
  }
}());
